.fissure-embed {
  width: fit-content;
  margin: 0 auto;
  background-color: #2f3136;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 16px;
}

.fissure-embed-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
}

.fissure-embed-select,
.fissure-embed-tier-select-dropdown {
  appearance: none;
  background-color: #202225;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='%23ffffff'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  padding-right: 32px;
}

.fissure-embed-select:hover,
.fissure-embed-tier-select-dropdown:hover {
  background-color: #2f3136;
}

.fissure-embed-select:focus,
.fissure-embed-tier-select-dropdown:focus {
  box-shadow: 0 0 0 2px #7289da;
}

.fissure-embed-tier-select {
  display: flex;
  align-items: center;
}

.fissure-embed-tier-label {
  margin-right: 8px;
  color: #ffffff;
}

.fissure-embed-tier-select-wrapper {
  position: relative;
  display: inline-block;
}

.fissure-embed-tier-select-dropdown {
  width: 50px;
  padding-right: 24px;
}

.fissure-embed-table {
  display: table;
  width: 100%;
  border-collapse: collapse;
}

.fissure-embed-row {
  display: table-row;
}

.fissure-embed-cell {
  display: table-cell;
  padding: 12px 16px;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #202225;
  white-space: nowrap;
}

.fissure-embed-header-row {
  font-weight: 600;
  background-color: #202225;
}

.fissure-embed-header-row .fissure-embed-cell {
  color: #b9bbbe;
  font-size: 14px;
  text-transform: uppercase;
}

.fissure-embed-row:last-child .fissure-embed-cell {
  border-bottom: none;
}

.fissure-embed-era {
  color: #7289da;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fissure-embed-era-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.fissure-embed-mission {
  color: #ffffff;
  position: relative;
}

.fissure-embed-mission-text {
  position: relative;
  z-index: 1;
}

.fissure-embed-mission-hover {
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #202225;
  color: #ffffff;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 2;
}

.fissure-embed-mission:hover .fissure-embed-mission-hover {
  visibility: visible;
  opacity: 1;
}

.fissure-embed-expiry {
  position: relative;
  color: #ffffff;
}

.fissure-embed-expiry-text {
  font-weight: 600;
}

.fissure-embed-expiry-hover {
  visibility: hidden;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #202225;
  color: #ffffff;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s;
}

.fissure-embed-expiry:hover .fissure-embed-expiry-hover {
  visibility: visible;
  opacity: 1;
}

.fissure-embed-config-button {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  transition: color 0.2s;
  margin-left: 8px;
}

.fissure-embed-config-button:hover {
  color: #7289da;
}

.fissure-embed-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #2f3136;
  border-radius: 8px;
  outline: none;
  z-index: 1000;
  width: 300px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.fissure-embed-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
}

.fissure-embed-modal-content {
  padding: 20px;
  overflow-y: auto;
}

.fissure-embed-modal h2 {
  margin: 0 0 20px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
}

.fissure-embed-modal-footer {
  background-color: #202225;
  padding: 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  justify-content: space-between;
}

.fissure-embed-modal-reset,
.fissure-embed-modal-save {
  appearance: none;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s;
}

.fissure-embed-modal-reset {
  background-color: #4f545c;
  color: #ffffff;
}

.fissure-embed-modal-reset:hover {
  background-color: #5c6269;
}

.fissure-embed-modal-save {
  background-color: #7289da;
  color: #ffffff;
}

.fissure-embed-modal-save:hover {
  background-color: #677bc4;
}

.fissure-embed-modal-reset:focus,
.fissure-embed-modal-save:focus {
  box-shadow: 0 0 0 2px #7289da;
}

.fissure-embed-config-options {
  display: flex;
  flex-direction: column;
}

.fissure-embed-config-options label {
  margin-bottom: 10px;
  color: #ffffff;
  font-size: 16px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

.fissure-embed-config-options input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.fissure-embed-checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #202225;
  border: 2px solid #7289da;
  border-radius: 4px;
  transform: translateY(-50%);
}

.fissure-embed-config-options
  input[type="checkbox"]:checked
  ~ .fissure-embed-checkmark {
  background-color: #7289da;
}

.fissure-embed-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.fissure-embed-config-options
  input[type="checkbox"]:checked
  ~ .fissure-embed-checkmark:after {
  display: block;
}

.fissure-embed-config-options .fissure-embed-checkmark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
